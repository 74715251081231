import React, { useCallback, useMemo, useState } from 'react';
import { Paper, Row, Col } from '@geneui/components';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { TranslationKeys } from '../../../core/constants/translationKeys';
import { useMobileSize } from '../../../hooks/useMobileSize';
import { useHistory } from 'react-router-dom';
import { authStore } from '../../../module';
import EnforceChangePasswordForm from './enforceChangePasswordForm';
import useTranslation from '../../../hooks/useTranslation';
import ForgotPasswordForm from './forgotPasswordForm';
import LinkSentSuccessfully from './linkSentSuccessfully';
import ResetPasswordForm from './resetPasswordForm';
import EmailConfirmation from './emailConfirmation';
import useQuery from '../../../hooks/useQuery';
import LoginForm from './loginForm';

import './index.css';

const LoginWr = styled.div`
  padding: ${props => props.isMobile ? 1 : 3}rem;
`;

const WelcomeTitle = styled.h2`
  color: #1473e6;
  font-size: 46px;
  font-weight: bold;
`;

const WelcomeDesc = styled.p`
  font-size: 26px;
  font-weight: bold;
  color: #3c4043;
  margin: 30px 0 20px;
`;

const WelcomeDetails = styled.p`
  color: #3c4043;
  font-size: 16px;
`;

const Login = observer(() => {

  const {
    requireChangePasswordData: {
      userId,
      currentPassword,
      isRequired: isRequiredToChangePass
    }
  } = authStore;

  const history = useHistory();
  const translate = useTranslation();
  const { queryObject } = useQuery();
  const { isMobile } = useMobileSize();
  const [ forgotMode, setForgotMode ] = useState(false);
  const [ linkSent, setLinkSent ] = useState(false);

  const toggleForgotPassowrd = useCallback(() => {
    setForgotMode(prev => !prev);
  }, []);

  const handleBackToSignInClick = useCallback(() => {
    setLinkSent(false);
    setForgotMode(false);
    history.push('/login');
  }, []);

  const rightSectionRenderer = useMemo(() => {
    const { token, email, expirationDate, userId: queryUserId, isEmailConfirmed, expireDate } = queryObject;
    if (isEmailConfirmed) {
      return (
        <EmailConfirmation
          token={token}
          userId={queryUserId}
          expireDate={expireDate}
        />
      );
    }
    if (linkSent) {
      return (
        <LinkSentSuccessfully
          onBackToSignInClick={handleBackToSignInClick}
        />
      );
    }
    if (token && queryUserId) {
      return (
        <ResetPasswordForm
          token={token}
          email={email}
          userId={queryUserId}
          setLinkSent={setLinkSent}
          expirationDate={expirationDate}
        />
      );
    }
    if (isRequiredToChangePass) {
      return (
        <EnforceChangePasswordForm
            userId={userId}
            currentPassword={currentPassword}
        />
      );
    }
    if (forgotMode) {
      return (
        <ForgotPasswordForm
          setLinkSent={setLinkSent}
          setForgotMode={setForgotMode}
        />);
    } else {
      return (
        <LoginForm
          onForgotPasswordClick={toggleForgotPassowrd}
        />
      );
    }
  }, [linkSent, userId, currentPassword, isRequiredToChangePass, forgotMode, queryObject]);

  return (
    <LoginWr isMobile={isMobile}>
      <Row>
        {
          !isMobile && (
          <Col size={7}>
            <Paper
              isTransparent
              padding={114}
              justifyContent="center"
              paperDirection="column"
              className="login-section left"
            >
              <WelcomeTitle>{translate(TranslationKeys.WELCOME)}!</WelcomeTitle>
              <WelcomeDesc>{translate(TranslationKeys.SIGN_IN_P1)}</WelcomeDesc>
              <WelcomeDetails>{translate(TranslationKeys.SIGN_IN_P2)}</WelcomeDetails>
            </Paper>
          </Col>
          )
        }
        <Col size={isMobile ? 12 : 5} className="login-right-column">
          { rightSectionRenderer }
        </Col>
      </Row>
    </LoginWr>
  );
});

export default Login;
