import React, { useCallback, useState } from 'react';
import { Paper, Row, Col, Alert } from '@geneui/components';
import styled from 'styled-components';

import { TranslationKeys } from '../../../../core/constants/translationKeys';
import { BackToLoginLink, LogoImg, RightSectionForm } from '../loginStyles';
import { useMobileSize } from '../../../../hooks/useMobileSize';
import { authStore, userStore } from '../../../../module';
import PasswordChangedSuccessfully from './passwordChangedSuccessfully';
import useTranslation from '../../../../hooks/useTranslation';
import Logo from '../../../../assets/images/logo.png';
import { Button, PasswordInput } from '../../../components/shared';

const ChangePassTitle = styled.span`
  font-size: 22px;
  font-weight: 600;
`;

const ChangePassDesc = styled.p`
  font-size: 16px;
  line-height: 1.38;
`;

const EnforceChangePasswordForm = ({
  userId,
  currentPassword,
}) => {

  const translate = useTranslation();
  const [inputValues, setInputValues] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const { isMobile } = useMobileSize();

  const handleChange = ({ target: { name, value } }) => {
    setInputValues(v => ({ ...v, [name]: value }));
  };

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    userStore.changePassword({
      userId,
      currentPassword,
      newPassword: inputValues.newPassword,
      confirmPassword: inputValues.confirmPassword,
    })
    .then(() => {
      setIsPasswordChanged(true);
    })
    .catch(res => {
      const errorMessage = res?.data?.errorMessage ;
      if (errorMessage) {
        setErrorMessage(errorMessage);
        setTimeout(() => {
          setErrorMessage('');
        }, 4000);
      }
    });
  }, [inputValues]);

  const handleBackToSignInClick = useCallback(e => {
    authStore.setRequireChangePasswordData({ requireChangePassword: false });
  }, []);

  const changePassword = !inputValues.newPassword || !inputValues.confirmPassword;

  return (
    <Paper
      border
      justifyContent="center"
      padding={isMobile ? 20 : 76}
      cornerRadius="full-radius"
      className={`login-section right ${isMobile ? 'mobile' : ''}`}
    >
      {
        errorMessage ? (
          <Alert
            type="error"
            screenType="desktop"
            message={translate(errorMessage)}
            title={translate(TranslationKeys.ERROR)}
            className={`login-error-alert ${isMobile ? 'mobile' : ''}`}
          />
        ) : null
      }
      {
        isPasswordChanged ?
        <PasswordChangedSuccessfully onBackToLogin={handleBackToSignInClick} /> :
        <RightSectionForm onSubmit={handleSubmit} isMobile={isMobile}>
          <Row span={1} padding={25}>
            <Col>
              <LogoImg src={Logo} isMobile={isMobile} />
            </Col>
            <Col>
              <ChangePassTitle>
                { translate(TranslationKeys.CHANGE_PASSWORD) }
              </ChangePassTitle>
            </Col>
            <Col>
              <ChangePassDesc>
              { translate(TranslationKeys.CHANGE_PASSWORD_DESC) }
              </ChangePassDesc>
            </Col>
            <Col>
              <PasswordInput
                inputSize="big"
                name="newPassword"
                value={inputValues.newPassword}
                onChange={handleChange}
                placeholder={translate(TranslationKeys.NEW_PASSWORD)}
                cornerRadius={isMobile ? 'smooth-radius' : 'full-radius'}
              />
            </Col>
            <Col>
              <PasswordInput
                inputSize="big"
                name="confirmPassword"
                onChange={handleChange}
                value={inputValues.confirmPassword}
                placeholder={translate(TranslationKeys.CONFIRM_PASSWORD)}
                cornerRadius={isMobile ? 'smooth-radius' : 'full-radius'}
              />
            </Col>
            <Col>
              <Button
                size="big"
                type="submit"
                flexibility="full-width"
                disabled={changePassword}
              >
                {translate(TranslationKeys.CHANGE_PASSWORD)}
              </Button>
            </Col>
            <Col>
              <BackToLoginLink onClick={handleBackToSignInClick}>
                {translate(TranslationKeys.BACK_TO_SIGN_IN)}
              </BackToLoginLink>
            </Col>
          </Row>
        </RightSectionForm>
      }
    </Paper>
  );
};

export default EnforceChangePasswordForm;
