import { ApiVersion } from '../../core/enums/ApiVersion';
import { httpClient } from '../httpClient/httpClient';
import { getPermissionsByUserType, getPermissionUserIdForEditUrl, getPermissionUserUrl } from '../../core/constants/endpoints';

class PermissionsService {
  /**
   * @name getPermissionsUserId
   * @param {number} userId
   * @param {ApiOption=} option
   * @return {Promise<PermissionsResponse>}
   */
  async getPermissionsUserId(userId, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getPermissionUserUrl(userId, option));
  }

  /**
   * @name getPermissionsByUserIdForEdit
   * @param {number} userId
   * @param {ApiOption=} option
   * @return {Promise<PermissionForEdit>}
   */
  async getPermissionsByUserIdForEdit(userId, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getPermissionUserIdForEditUrl(userId, option));
  }

  /**
   * @name getPermissionsByUserType
   * @param {number} userType
   * @param {ApiOption=} option
   * @return {Promise<PermissionsResponse>}
   */
  async getPermissionsByUserType(userType, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getPermissionsByUserType(option), { params: { userType } });
  }
}

export const permissionsService = new PermissionsService();
