import styled from 'styled-components';

export const RightSectionForm = styled.form`
  width: ${props => props.isMobile ? '100%' : '36rem'};
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const BackToLoginLink = styled.a`
  color: #1c82f4;
  margin-left: 1rem;
  cursor: pointer;
`;

export const LogoImg = styled.img`
  width: ${props => props.isMobile ? 18 : 22}rem;
`;