import { TranslationKeys as T } from '../constants/translationKeys';

export const UserRole = {
  SuperAdmin: 0,
  Admin: 1,
  GroupManager: 2,
  Player: 3,
  Manager: 4,
};

export const UserRoleName = {
  [UserRole.SuperAdmin]: T.SUPER_ADMIN,
  [UserRole.Admin]: T.ADMIN,
  [UserRole.GroupManager]: T.GROUP_MANAGER,
  [UserRole.Player]: T.PLAYER,
  [UserRole.Manager]: T.MANAGER,
};
