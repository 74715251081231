import React, { useCallback, useMemo, useState } from 'react';
import { Alert, Paper, Row, Col } from '@geneui/components';
import styled from 'styled-components';

import { TranslationKeys as T } from '../../../../core/constants/translationKeys';
import { BackToLoginLink, LogoImg, RightSectionForm } from '../loginStyles';
import { REGEX_CONSTANTS } from '../../../../core/constants/RegEx';
import { useMobileSize } from '../../../../hooks/useMobileSize';
import { userService } from '../../../../module';
import useTranslation from '../../../../hooks/useTranslation';
import Logo from '../../../../assets/images/logo.png';
import { Button, TextInput } from '../../../components/shared';

const ForgotPassTitle = styled.span`
  font-size: 22px;
  font-weight: 600;
`;

const ForgotPassDesc = styled.p`
  font-size: 16px;
  line-height: 1.38;
`;

const ForgotPasswordForm = ({ setForgotMode, setLinkSent }) => {

  const translate = useTranslation();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { isMobile } = useMobileSize();

  const handleChange = useCallback(({ target: { value } }) => {
    setEmail(value);
  }, []);
  
  const handleInputBlur = useCallback(({ target: { value } }) => {
    const isOk = REGEX_CONSTANTS.Email.test(value);
    setEmailError(!isOk);
  }, []);

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    userService
      .sendResetPasswordEmail({ email })
      .then(() => {
        setLinkSent(true);
      })
      .catch(res => {
        const errorMessage = res?.data?.errorMessage;
        if (errorMessage) {
          setErrorMessage(errorMessage);
          setTimeout(() => {
            setErrorMessage('');
          }, 4000);
        }
      });
  }, [email]);

  const handleBackToSignInClick = useCallback(() => {
    setForgotMode(false);
  }, []);

  const isDisabled = useMemo(() => !email || emailError, [email, emailError]);

  return (
    <Paper
      border
      justifyContent="center"
      cornerRadius="full-radius"
      padding={isMobile ? 20 : 76}
      className={`login-section right ${isMobile ? 'mobile' : ''}`}
    >
      {
        errorMessage ? (
          <Alert
            type="error"
            screenType="desktop"
            message={translate(errorMessage)}
            title={translate(T.ERROR)}
            className={`login-error-alert ${isMobile ? 'mobile' : ''}`}
          />
        ) : null
      }
      <RightSectionForm onSubmit={handleSubmit} isMobile={isMobile}>
        <Row span={1} padding={25}>
          <Col>
            <LogoImg src={Logo} isMobile={isMobile} />
          </Col>
          <Col>
            <ForgotPassTitle>
              { translate(T.RESTORE_PASSWORD) }
            </ForgotPassTitle>
          </Col>
          <Col>
            <ForgotPassDesc>
            { translate(T.RESTORE_PASSWORD_DESC) }
            </ForgotPassDesc>
          </Col>
          <Col>
            <TextInput
              name="email"
              value={email}
              inputSize="big"
              isValid={!emailError}
              onChange={handleChange}
              onBlur={handleInputBlur}
              placeholder={translate(T.EMAIL)}
              errorText={translate(T.WRONG_EMAIL_FORMAT)}
              cornerRadius={isMobile ? 'smooth-radius' : 'full-radius'}
            />
          </Col>
          <Col>
            <Button
              size="big"
              type="submit"
              flexibility="full-width"
              disabled={isDisabled}
            >
              {translate(T.SEND_RESET_LINK)}
            </Button>
          </Col>
          <Col>
            <BackToLoginLink onClick={handleBackToSignInClick}>
              {translate(T.BACK_TO_SIGN_IN)}
            </BackToLoginLink>
          </Col>
        </Row>
      </RightSectionForm>
    </Paper>
  );
};

export default ForgotPasswordForm;
