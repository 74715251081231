import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { CheckboxGroup, Paper } from '@geneui/components';
import styled from 'styled-components';

import { platformStore } from '../../../../../module';
import {
  gameEnumCheckboxConverter,
  gameIdCheckboxConverter,
} from '../../../../../core/helpers/dropdownDataConverter';
import {
  getFilteredData,
  getSelectedCategoriesGames,
} from '../../../../../core/helpers/createPartnerHelpers';
import useTranslation from '../../../../../hooks/useTranslation';
import ContainerSearch from '../../containerSearch';
import { TranslationKeys } from '../../../../../core/constants/translationKeys';
import ContainerTitle from '../../../transferCheckbox/containerTitle';
import { Divider } from '../../../shared';
import { GameCategoryNames } from '../../../../../core/enums/GameCategory';

export const ListContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;

  & .category_checkbox {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    height: 400px;
    overflow: auto;
    flex-wrap: nowrap;
  }

  & .crs-holder {
    border-radius: 14px;
    margin-right: 0;
    position: relative;
    height: 38px;
    overflow: hidden;
    border: 1px solid #e6e6e6;
  }

  & .label-holder {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  & .crs-item.crs-label {
    flex-grow: 1;
    display: block;
  }
`;

const Category = observer(({
  setGamesCategory,
  setSelectedGames,
  gamesCategory,
  setFilteredGameByCategory,
  values,
}) => {
  const translate = useTranslation();
  const [checkBoxFilter, setCheckBoxFilter] = useState(gameEnumCheckboxConverter(GameCategoryNames));
  const { platformsGames: { data: games } } = platformStore;

  useEffect(() => {
    platformStore.getPlatformsGames(values.platformId);
  }, [values.platformId]);

  useEffect(() => {
    setFilteredGameByCategory([...gameIdCheckboxConverter(getSelectedCategoriesGames(games, gamesCategory))]);
  }, [games, gamesCategory]);

  useEffect(() => {
    setSelectedGames((prev) => {
      return prev.filter((game) => gamesCategory.includes(game.category));
    });
  }, [gamesCategory]);

  const handleCategorySearch = useCallback((event) => {
    setCheckBoxFilter([...getFilteredData(event, gameEnumCheckboxConverter(GameCategoryNames))]);
  }, [GameCategoryNames]);

  const handleCategoryChange = useCallback((enumKey) => {
    setGamesCategory([...enumKey]);
  }, []);

  const titleRightValue = `${gamesCategory.length}/${checkBoxFilter.length}`;

  const gameLineData = useMemo(() => {
    return checkBoxFilter.filter(({ value: category }) => {
      return games.some((game) => game.category === +category);
    });
  }, [checkBoxFilter, games]);

  return (
    <Paper
      paperDirection="column"
      cornerRadius="full-radius"
      isTransparent
      padding={0}
      withLine
      shadow
      border
    >
      <ContainerTitle text={translate(TranslationKeys.CATEGORY)} value={titleRightValue}/>
      <Divider
        type="horizontal"
        size="379"
      />
      <ContainerSearch onChange={handleCategorySearch}/>
      <Divider
        type="horizontal"
        size="379"
      />
      <ListContent>
        <CheckboxGroup
          showSelectAll={!!checkBoxFilter.length}
          onChange={handleCategoryChange}
          className="category_checkbox"
          data={gameLineData.map((item) => ({...item, label: translate(item.label)}))}
        />
      </ListContent>
    </Paper>
  );
});

export default Category;
