import { httpClient } from '../httpClient/httpClient';
import { ApiVersion } from '../../core/enums/ApiVersion';
import {
  signInUrl,
  signOutUrl,
  refreshTokenUrlConstant,
} from '../../core/constants/endpoints';

class AuthService {
  /**
   * @name signIn
   * @description this endpoint need  for  login user
   * @param {AddAuthBody} data
   * @param {ApiOption=} option
   * @returns {Promise<AuthTokenResponse>}
   */
  async signIn(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(signInUrl(option), data);
  }

  /**
   * @name refreshToken
   * @description this endpoint need for refresh Token
   * @return {Promise<AuthTokenResponse>}
   */
  async refreshToken() {
    return await httpClient.post(refreshTokenUrlConstant);
  }

  /**
   * @name signOut
   * @description this endpoint need for signOut
   * @param {ApiOption=} option
   * @return {Promise<void>}
   */
  async signOut(option = { version: ApiVersion.V1 }) {
    return await httpClient.post(signOutUrl(option));
  }
}

export const authService = new AuthService();
