export const PERMISSIONS = {
  ViewDashboard: 'view_dashboard',
  ViewPartnerActivity: 'view_partner_activity',
  ViewNotifications: 'view_notifications',
  UpdateAllNotifications: 'update_all_notifications',
  AddPlatform: 'add_platform',
  AddPartner: 'add_partner',
  ViewPartnerSettings: 'view_partner_settings',
  UpdatePartnerSettings: 'update_partner_settings',
  ViewPlatformSettings: 'view_platform_settings',
  UpdatePlatformSettings: 'update_platform_settings',
  UpdateGames: 'update_games',
  ViewGames: 'view_games',
  ViewGameTypes: 'view_game_types',
  UpdateGameTypes: 'update_game_types',
  ViewTableBetReport: 'view_table_bet_report',
  ViewTableUserReport: 'view_table_user_report',
  ViewTableRoundReport: 'view_table_round_report',
  ViewBogBetReport: 'view_bog_bet_report',
  ViewBogUserReport: 'view_bog_user_report',
  ViewBogRoundReport: 'view_bog_round_report',
  ViewArcadeBetReport: 'view_arcade_bet_report',
  ViewArcadeUserReport: 'view_arcade_user_report',
  ViewFinancialReport: 'view_financial_report',
  ViewTransactionReport: 'view_transaction_report',
  ViewFreebetReport: 'view_freebet_report',
  ViewBonusIsland: 'view_bonus_island',
  UpdateBonusIsland: 'update_bonus_island',
  CancelBonusIslandRow: 'cancel_bonus_island_row',
  ViewBonusIslandPlayersRow: 'view_bonus_island_players_row',
  ViewBonusIslandList: 'view_bonus_island_list',
  ViewBonusIslandDetailsRow: 'view_bonus_island_details_row',
  UpdateFreebet: 'update_freebet',
  ViewFreebet: 'view_freebet',
  ViewFreebetDetailsRow: 'view_freebet_details_row',
  CancelFreebetRow: 'cancel_freebet_row',
  ViewUnderMaintenance: 'view_under_maintenance',
  UpdateUnderMaintenance: 'update_under_maintenance',
  ViewStaticContent: 'view_static_content',
  UpdateStaticContent: 'update_static_content',
  ViewUserManagement: 'view_user_management',
  UpdateUserManagment: 'update_user_managment',
  UpdateUserStatusRow: 'update_user_status_row',
  UpdateTranslations: 'update_translation',
  AddTranslations: 'add_translation',
  DeleteTranslations: 'delete_translation',
  ViewTranslations: 'view_translations',
  ViewTranslationGroups: 'view_groups',
  UpdateTranslationGroups: 'update_groups',
  ViewManageGroups: 'view_manage_groups',
  UpdateLanguages: 'update_languages',
  ViewLanguages: 'view_languages',
  EditApplications: 'edit_applications',
  ViewApplications: 'view_applications',
  UpdateUserApplications: 'update_user_applications',
  AwardFreeBetRow: 'award_free_bet_row',
  ViewLottoBetReport: 'view_lotto_bet_report',
  ViewLottoUserReport: 'view_lotto_user_report',
  ViewLottoRoundReport: 'view_lotto_round_report',
  ViewBetshopBetReport: 'view_betshop_bet_report',
  ViewBetshopUserReport: 'view_betshop_user_report',
  ViewBetshopMarketsReport: 'view_betshop_round_report', // todo: change to view_betshop_markets_report
  ViewBetshopRoundReport: 'view_betshop_round_report',
  ViewSliderManager: 'view_slider_manager',
  UpdateSliderManager: 'update_slider_manager',
  ViewCurrencyList: 'view_currency_list', // todo
  ViewSlotBetReport: 'view_slots_bet_report',
  ViewSlotUserReport: 'view_slots_user_report',
  ViewSlotRoundReport: 'view_slots_round_report',
  ViewVirtualBetReport: 'view_virtual_bet_report',
  ViewVirtualRoundReport: 'view_virtual_round_report',
  ViewVirtualUserReport: 'view_virtual_user_report',
  ViewVirtualMarketsReport: 'view_virtual_markets_report',
  ViewCryptoGamesBetReport: 'view_crypto_bet_report',
  ViewCryptoGamesRoundReport: 'view_crypto_round_report',
  ViewCryptoGamesUserReport: 'view_crypto_user_report',
  ViewPlayerPromoReport: 'view_player_promo_report',
  ViewPromoList: 'view_promo_list',
  AddPromo: 'add_promo',
  EditPromo: 'edit_promo',
  CancelPromo: 'cancel_promo',
  ViewJackpot: 'view_jackpot',
  AddJackpot: 'add_jackpot',
  UpdateJackpot: 'update_jackpot',
  ViewJackpotReportRow: 'view_jackpot_report_row',
  ViewPlayerJackpotReport: 'view_player_jackpot_report',
  ViewTournamentList: 'view_tournament_list',
  UpdateTournament: 'update_tournament',
  ViewTournamentReport: 'view_tournament_report', // TODO
  ViewTournamentReportByGame: 'view_tournament_report_by_game', //TODO
  ViewTournamentReportByPlayer: 'view_tournament_report_by_player', //TODO
  ViewComparisonReport: 'view_comparison_report',
  ViewInvoiceReport: 'view_invoice_report',
  ViewCashbackList: 'view_cashback_list',
  UpdateCashback: 'update_cashback',
  ViewUserReport: 'view_dashboard', // todo
  ViewCashbackReport: 'view_cashback_report',
  ViewCashbackPlayerReport: 'view_cashback_player_report',
  ViewCurrencyReportList :'view_currency_report_list',
};

export const DEFAULT_INCLUDED_PERMISSIONS = [
  {
    name: 'View Dashboard',
    title: 'View Dashboard',
    id: PERMISSIONS.ViewDashboard,
  }
];
