import React from 'react';
import styled from 'styled-components';

import { TranslationKeys } from '../../../../../core/constants/translationKeys';
import { useMobileSize } from "../../../../../hooks/useMobileSize";
import { RightSectionForm } from '../../loginStyles';
import useTranslation from '../../../../../hooks/useTranslation';
import { Icon } from '../../../../components/shared';

const InfoTitle = styled.h4`
  font-size: 22px;
  font-weight: 600;
  line-height: 1.36;
  margin-bottom: 4rem;
  text-align: center;
`;

const InfoText = styled.p`
  font-size: 16px;
  line-height: 1.38;
  margin: 4rem 0 3rem;
  text-align: center;
`;

const InfoIcon = styled.div`
  position: relative;
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 7.5rem;
  background-color: #ffc06c;
  .warning-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 48px;
  }
`;

const ConfirmationLinkExpired = () => {

  const translate = useTranslation();
  const { isMobile } = useMobileSize();

  return (
    <RightSectionForm isMobile={isMobile}>
      <InfoTitle>
        { translate(TranslationKeys.EMAIL_CONFRIMATION_LINK_EXPIRED) }
      </InfoTitle>
      <InfoIcon>
        <Icon className="warning-icon" type="bc-icon-danger-48" />
      </InfoIcon>
      <InfoText>
        { translate(TranslationKeys.EMAIL_CONFRIMATION_LINK_EXPIRED_DESC) }
      </InfoText>
    </RightSectionForm>
  );
};

export default ConfirmationLinkExpired;
