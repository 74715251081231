import { action, makeObservable, observable } from 'mobx';

import { ACCESS } from '../../core/constants/util';
import { authService } from './authService';
import { getIsAuthDefaultValue } from '../../core/constants/authUtil';
import LocalStorageHelper from '../../core/helpers/localStorageHelper';
import { userStore } from '../userModule';
import { HTTPStatus } from '../../core/enums/HTTPStatus';
import { modalStore } from '../popUpModule';

class AuthStore {
  isAuth = getIsAuthDefaultValue();
  expirationInsurance = 100;
  refreshTokenTimer = null;
  requireChangePasswordData = {};
  /**
   * @name manualAuth
   * @type {boolean}
   * @desc Boolean value which indicates whether the user logged in manually or with refresh token. 
   */
  manualAuth = false;

  constructor() {
    makeObservable(this, {
      isAuth: observable.struct,
      manualAuth: observable.struct,
      requireChangePasswordData: observable,
      refreshTokenTimer: observable.ref,
      setAuth: action,
      setManualAuth: action,
      setRequireChangePasswordData: action,
      signIn: action,
      refreshToken: action,
      signOut: action,
    });
  }

  /**
   * @name setAuth
   * @param {boolean} value
   * @returns {void}
   */
  setAuth = (value) => {
    this.isAuth = value;
  };

  /**
   * @name setManualAuth
   * @param {boolean} value
   * @returns {void}
   */
   setManualAuth = (value) => {
    this.manualAuth = value;
  };

  /**
   * @name setRequireChangePasswordData
   * @param {ChangePasswordData} value
   * @returns {void}
   */
  setRequireChangePasswordData = (value) => {
    this.requireChangePasswordData = value;
  };

  /**
   * @name signIn
   * @param {AddAuthBody} data
   * @returns {Promise<AuthTokenResponse>}
   */
  signIn = async (data) => {
    // Set blocked user (in case of user blocked)
    userStore.setBlockedUser({
      isBlocked: false,
      _userName: data.username
    });
    const response = await authService.signIn(data);
    this.setRequireChangePasswordData({
      userId: response?.userId,
      currentPassword: data.password,
      isRequired: response?.requireChangePassword,
    });
    if (!response?.requireChangePassword) {
      this._attachTimer(response);
      this._setupAccessInMemory(response);
    }
    return response;
  };

  /**
   * @name refreshToken
   * @returns {Promise<AuthTokenResponse>}
   */
  refreshToken = async () => {
    const response = await authService.refreshToken();
    this._attachTimer(response);
  };

  /**
   * @name _setupAccessInMemory
   * @param {AuthTokenResponse} response
   * @private
   */
  _setupAccessInMemory = (response) => {
    LocalStorageHelper.deleteItem(ACCESS);
    response.expiresIn = (response.expiresIn - this.expirationInsurance) * 1000 + Date.now();
    LocalStorageHelper.setItem(ACCESS, response);
  };

  /**
   * @name _attachTimer
   * @param {AuthTokenResponse} response
   * @private
   */
  _attachTimer = (response) => {
    clearTimeout(this.refreshTokenTimer);
    const timer = (response.expiresIn - this.expirationInsurance) * 1000;
    this._setupAccessInMemory(response);
    this.refreshTokenTimer = setTimeout(() => {
      authService.refreshToken()
        .then(res => {
          this._attachTimer(res);
          this._setupAccessInMemory(res);
        })
        .catch(() => {
          this.signOut();
        });
    }, timer);
  };

  /**
   * @name signOut
   * @returns {Promise<void>}
   */
  signOut = async () => {
    this.setAuth(false);
    clearTimeout(this.refreshTokenTimer);
    LocalStorageHelper.deleteItem(ACCESS);
    return await authService.signOut();
  };
}

export const authStore = new AuthStore();
