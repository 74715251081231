let DOMAIN = '';

if (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'development') {
  // production | stage
  DOMAIN = window.location.origin;
} else {
  // local
  DOMAIN = 'https://platform-stage.rpd.cloud';
}

export const API_CORE = `${DOMAIN}/api`;
