import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import {
  FilterHeader,
  FilteredName,
  PartnerName,
  PartnerGroup,
} from './partnerFilter.styled';
import { Icon, Popover } from '../../shared';
import { PartnerPlatformFilter } from './PartnerPlatformFilter';
import { platformStore } from '../../../../module';
import useTranslation from '../../../../hooks/useTranslation';
import { usePlatformPartnerFilter } from '../../../../hooks/usePlatformPartnerFilter';
import { combineQuery, parseQuery } from '../../../../core/helpers/queryHelper';
import useOutSideClick from '../../../../hooks/useOutSideClick';
import { TranslationKeys } from '../../../../core/constants/translationKeys';
import { useMobileSize } from '../../../../hooks/useMobileSize';
import MobilePopoverFooter from '../../mobilePopoverFooter';
import useQuery from '../../../../hooks/useQuery';

const PartnerFilter = observer(() => {
  const ref = useRef();
  const { platformsPartners: { data: { items: platformsPartners } } } = platformStore;
  const { search } = useLocation();
  const { queryObject } = useQuery();
  const history = useHistory();
  const translate = useTranslation();
  const { isMobile } = useMobileSize();
  const { partnerIdsWithPlatform, handlePartnersSelect } = usePlatformPartnerFilter(platformsPartners);
  const [names, setNames] = useState({
    platformName: '',
    partnerName: '',
  });
  const [isOpen, setIsOpen] = useState(false);
  const [platformSearchValue, setPlatformSearchValue] = useState('');
  const [partnerSearchValue, setPartnerSearchValue] = useState('');
  const [selectedPlatformId, setSelectedPlatformId] = useState(
    parseQuery(search).platformId !== undefined
      ? +parseQuery(search).platformId
      : -1);
  const [partnerListView, setPartnerListView] = useState(false);

  const handlePlatformClick = useCallback((id) => {
    const selectedPlatform = platformsPartners.find(({ id: platformId }) => id === platformId);
    if (selectedPlatform && id !== selectedPlatformId) {
      const { partners } = selectedPlatform;
      handlePartnersSelect(id, partners.map(({ id }) => id));
    }
    setSelectedPlatformId(id);
    setPartnerListView(true);
  }, [platformsPartners, handlePartnersSelect, selectedPlatformId]);

  const handleBackPlatformList = useCallback(() => {
    setPartnerListView(false);
    setPartnerSearchValue('');
    setPlatformSearchValue('');
  }, []);

  const handlePlatformSearch = useCallback(({ target: { value } }) => {
    setPlatformSearchValue(value);
  }, []);

  const handlePartnerSearch = useCallback(({ target: { value } }) => {
    setPartnerSearchValue(value);
  }, []);

  const handlePartnerListChange = useCallback((list) => {
    if (!Array.isArray(list)) return;
    handlePartnersSelect(selectedPlatformId, list);
  }, [partnerIdsWithPlatform, handlePartnersSelect, selectedPlatformId]);

  const platformsPartnersData = useMemo(() => {
    if (!platformSearchValue) {
      return platformsPartners;
    }
    return platformsPartners.filter(platform =>
      platform.name.toLowerCase().includes(platformSearchValue.toLowerCase()) ||
      `${platform.id}`.includes(platformSearchValue));
  }, [platformsPartners, platformSearchValue]);

  const selectedPlatformData = useMemo(() => {
    return platformsPartners.find(({ id }) => id === Number(selectedPlatformId)) || {};
  }, [selectedPlatformId, platformsPartners]);

  const partnersData = useMemo(() => {
    const allPartners = (selectedPlatformData?.partners || []).map(({ id, name }) => ({
      value: id,
      _label: name,
      label: (
        <PartnerGroup>
          <div>{name}</div>
          <div>{id}</div>
        </PartnerGroup>
      ),
    }));
    if (!partnerSearchValue) {
      return allPartners;
    }
    return allPartners.filter(partner =>
      partner._label.toLowerCase().includes(partnerSearchValue.toLowerCase()) ||
      `${partner.value}`.includes(partnerSearchValue));

  }, [selectedPlatformData, partnerSearchValue]);

  const togglePopover = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    setNames(n => ({
      ...n,
      platformName: selectedPlatformData.name,
    }));
  }, [selectedPlatformData.id]);

  useOutSideClick(ref, () => {
    setIsOpen(false);
    setPlatformSearchValue(null);
  }, 'ignore-partner');

  const partnerName = useMemo(() => {
    if (!selectedPlatformData?.partners) {
      return translate(TranslationKeys.PLS_SELECT_PARTNERS);
    }
    const { partnerIds, platformId } = parseQuery(search);
    if (typeof partnerIds === 'string') {
      return selectedPlatformData?.partners?.find(({ id }) => id === Number(partnerIds))?.name;
    }
    const selectedPlatformPartnersCount = selectedPlatformData?.partners?.length;
    if (platformId) {
      if (selectedPlatformPartnersCount === 0) {
        return translate(TranslationKeys.PLS_SELECT_PARTNERS);
      }
      return `${partnerIds?.length || selectedPlatformPartnersCount} - ${translate(TranslationKeys.PARTNER)}`;
    }

    if (!partnerIds) {
      return translate(TranslationKeys.PLS_SELECT_PARTNERS);
    }

    return `${partnerIds.length} - ${translate(TranslationKeys.PARTNER)}`;
  }, [search, selectedPlatformData, translate]);

  const handleResetSelectedPlatform = useCallback(() => {
    const { platformId, partnerIds, skip, take, ...restQuery } = queryObject;
    history.push({
      search: combineQuery(restQuery)
    });
    setNames({
      platformName: '',
      partnerName: ''
    });
    setSelectedPlatformId(-1);
  }, [history, queryObject]);

  const isDisabledResetButton = useMemo(() => !queryObject.platformId && !queryObject.partnerIds, [queryObject]);

  return (
    <Popover
      contentRef={ref}
      isOpen={isOpen}
      extendTargetWidth={false}
      maxWidth="200px"
      align="end"
      toggleHandler={togglePopover}
      Content={(
        <PartnerPlatformFilter
          partnersData={partnersData}
          partnerListView={partnerListView}
          selectedPlatform={selectedPlatformId}
          handlePartnerSearch={handlePartnerSearch}
          handlePlatformClick={handlePlatformClick}
          selectedPlatformData={selectedPlatformData}
          handlePlatformSearch={handlePlatformSearch}
          isDisabledResetButton={isDisabledResetButton}
          platformsPartnersData={platformsPartnersData}
          handleBackPlatformList={handleBackPlatformList}
          partnerIdsWithPlatform={partnerIdsWithPlatform}
          handlePartnerListChange={handlePartnerListChange}
          handleResetSelectedPlatform={handleResetSelectedPlatform}
        />
      )}
      Footer={isMobile ? <MobilePopoverFooter onCancel={togglePopover}/> : null}
    >
      <button className="ignore-partner">
        <FilterHeader mobile={isMobile}>
          <FilteredName mobile={isMobile}>
            {names.platformName || translate(TranslationKeys.SELECT_PLATFORM)}
            <PartnerName>
              {partnerName}
            </PartnerName>
          </FilteredName>
          <Icon
            className="icon-down"
            type="bc-icon-arrow-down"
          />
        </FilterHeader>
      </button>
    </Popover>
  );
});

export default PartnerFilter;
