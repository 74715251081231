import React, { useCallback } from 'react';
import styled from 'styled-components';

import { TranslationKeys } from '../../../../../core/constants/translationKeys';
import { useMobileSize } from "../../../../../hooks/useMobileSize";
import { RightSectionForm } from '../../loginStyles';
import { userService } from '../../../../../module';
import useTranslation from '../../../../../hooks/useTranslation';
import { Button, Icon } from '../../../../components/shared';

const InfoTitle = styled.h4`
  font-size: 22px;
  font-weight: 600;
  line-height: 1.36;
  margin-bottom: 4rem;
`;

const InfoText = styled.p`
  font-size: 16px;
  line-height: 1.38;
  margin: 4rem 0 3rem;
`;

const InfoIcon = styled.div`
  position: relative;
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 7.5rem;
  background-color: #ffc06c;
  .warning-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 48px;
  }
`;

const ResetLinkExpired = ({
  email,
  setLinkSent,
  setErrorMessage,
}) => {

  const translate = useTranslation();
  const { isMobile } = useMobileSize();

  const handleResendClick = useCallback(() => {
    userService
      .sendResetPasswordEmail({ email })
      .then(() => {
        setLinkSent(true);
      })
      .catch(res => {
        const errorMessage = res?.data?.errorMessage;
        if (errorMessage) {
          setErrorMessage(errorMessage);
          setTimeout(() => {
            setErrorMessage('');
          }, 4000);
        }
      });
  }, [email]);

  return (
    <RightSectionForm isMobile={isMobile}>
      <InfoTitle>
        { translate(TranslationKeys.RESTORATION_LINK_EXPIRED) }
      </InfoTitle>
      <InfoIcon>
        <Icon className="warning-icon" type="bc-icon-danger-48" />
      </InfoIcon>
      <InfoText>
        { translate(TranslationKeys.RESEND_DESC) }
      </InfoText>
      <Button
        size="big"
        type="button"
        onClick={handleResendClick}
        flexibility="full-width"
      >
        { translate(TranslationKeys.RESEND) }
      </Button>
    </RightSectionForm>
  );
};

export default ResetLinkExpired;
