import { permissionStore } from '../../module';

export const mapPermissionsToTransferListDataModel = (permissions, translateFn) => {
  const translate = translateFn || (arg => arg);
  return permissions.map(({ key, name }) => ({
    id: key,
    name: translate(name),
    title: translate(name)
  }));
};

/**
 * @name hasPermission
 * @param {Array<string>} requiredPermissions 
 * @param {boolean} [checkAll]
 * @returns boolean
 */
export const hasPermission = (requiredPermissions, checkAll = true) => {
  const usersPermissions = permissionStore.permissions.data;
  if (!requiredPermissions) return true;
  if (!usersPermissions) return false;
  const checkMethod = checkAll ? Array.prototype.every : Array.prototype.some;
  return checkMethod.call(requiredPermissions, (permission => {
    return usersPermissions.find(userPermission => userPermission.key === permission);
  }));
};
