import { action, flow, makeObservable, observable } from 'mobx';

import { HttpResponse } from '../../core/classes/HttpResponse';
import { permissionsService } from './permissionsService';

class PermissionStore {
  /** @type {HttpResponse<PermissionsResponse>}*/
  permissions = new HttpResponse([]);

  /** @type {HttpResponse<PermissionForEdit>}*/
  permissionForEdit = new HttpResponse([]);

  /** @type {HttpResponse<PermissionForEdit>}*/
  permissionsByUserType = new HttpResponse([]);

  constructor() {
    makeObservable(this, {
      permissions: observable,
      permissionForEdit: observable,
      permissionsByUserType: observable,
      setPermission: action.bound,
      setPermissionForEdit: action.bound,
      setPermissionsByUserType: action.bound,
      getPermissionsUserId: flow,
      getPermissionsByUserIdForEdit: flow,
      getPermissionsByUserType: flow,
    });
  }

  /**
   * @name setPermission
   * @params {HttpResponse<PermissionsResponse>} permissions
   * @returns {void}
   */
  setPermission = (permissions) => {
    this.permissions = permissions;
  };

  /**
   * @name setPermissionForEdit
   * @params {HttpResponse<PermissionForEdit>} permissionForEdit
   * @returns {void}
   */
  setPermissionForEdit = (permissionForEdit) => {
    this.permissionForEdit = permissionForEdit;
  };

  /**
   * @name setPermissionsByUserType
   * @params {HttpResponse<PermissionsResponse>} data
   * @returns {void}
   */
  setPermissionsByUserType = (data) => {
    this.permissionsByUserType = data;
  };

  /**
   * @name getPermissionsUserId
   * @param {number} userId
   * @returns {Generator<Promise<void>, void, *>}
   */
  getPermissionsUserId = function* (userId) {
    this.setPermission(this.permissions.fetching());
    const data = yield permissionsService.getPermissionsUserId(userId);
    this.setPermission(this.permissions.fetched(data));
  };

  /**
   * @name getPermissionsByUserIdForEdit
   * @param {number} userId
   * @returns {Generator<Promise<PermissionForEdit>, void, *>}
   */
  getPermissionsByUserIdForEdit = function* (userId) {
    this.setPermissionForEdit(this.permissionForEdit.fetching());
    const data = yield permissionsService.getPermissionsByUserIdForEdit(userId);
    this.setPermissionForEdit(this.permissionForEdit.fetched(data));
  };

  /**
   * @name getPermissionsByUserType
   * @param {number} userType
   * @returns {Generator<Promise<PermissionsResponse>, void, *>}
   */
  getPermissionsByUserType = function* (userType) {
    this.setPermissionsByUserType(this.permissionsByUserType.fetching());
    const data = yield permissionsService.getPermissionsByUserType(userType);
    this.setPermissionsByUserType(this.permissionsByUserType.fetched(data));
  };
}

export const permissionStore = new PermissionStore();
