import { httpClient } from '../httpClient/httpClient';
import { ApiVersion } from '../../core/enums/ApiVersion';
import {
  addUserUrl,
  getUsersUrl,
  editUserUrl,
  editIfBlockUrl,
  confirmEmailUrl,
  editPasswordUrl,
  editUserInfoUrl,
  getUsersInfoUrl,
  resetPasswordUrl,
  sendEmailConfirmationUrl,
  sendResetPasswordEmailUrl,
  getUserDetailedUrl,
  getCustomizationConfigUrl,
  editCustomizationConfigUrl,
} from '../../core/constants/endpoints';

class UserService {
  /**
   * @name addUser
   * @description this endpoint need  for  add  new  User
   * @param {AddUserBody} data
   * @param {ApiOption=} option
   * @returns {Promise<AddUserResponse>}
   */
  async addUser(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(addUserUrl(option), data);
  }

  /**
   * @name getUsers
   * @description this endpoint need for get User
   * @param {UserQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<UserResponse>}
   */
  async getUsers(params, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getUsersUrl(option), {
      params,
    });
  }

  /**
   * @name editUser
   * @description this endpoint need for edit user field
   * @param {UserUpdateBody} data
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async editUser(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editUserUrl(option), data);
  }

  /**
   * @name editIfBlock

   * @param {number} id
   * @param {BlockUserBody} params
   * @returns {Promise<void>}
   */
  async editIfBlock(id, params, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editIfBlockUrl(id, option), null, { params });
  }

  /**
   * @name getUsersInfo
   * @description this endpoint need for get User info
   * @param {ApiOption=} option
   * @returns {Promise<UserInfoResponse>}
   */
  async getUsersInfo(option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getUsersInfoUrl(option));
  }

  /**
   * @name editUserInfo
   * @description this endpoint need for edit User info
   * @param {EditUserInfoBody} data
   * @param {ApiOption=} option
   * @returns {Promise<EditUserInfoResponse>}
   */
  async editUserInfo(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editUserInfoUrl(option), data);
  }

  /**
   * @name changePassword
   * @description this endpoint need for edit User password
   * @param {ChangePasswordBody} data
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async changePassword(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editPasswordUrl(option), data);
  }

  /**
   * @name sendResetPasswordEmail
   * @description this endpoint need for send send reset password email of user
   * @param {ResetPasswordEmailQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async sendResetPasswordEmail(params, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(sendResetPasswordEmailUrl(option), null, { params });
  }

  /**
   * @name resetPassword
   * @description this endpoint need for reset password of user
   * @param {ResetPasswordBody} data
   * @param {ApiOption=} option
   * @return {Promise<void>}
   */
  async resetPassword(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(resetPasswordUrl(option), data);
  }

  /**
   * @name sendEmailConfirmation
   * @description sendEmailConfirmation
   * @param {number} id
   * @param {ApiOption=} option
   * @return {Promise<void>}
   */
  async sendEmailConfirmation(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(sendEmailConfirmationUrl(id, option));
  }

  /**
   * @name confirmEmail
   * @param {number} id
   * @param {EmailConfirmationBody} data
   * @param {ApiOption=} option
   * @return {Promise<void>}
   */
  async confirmEmail(id, data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(confirmEmailUrl(id, option), data);
  }

  /**
   * @name confirmEmail
   * @param {number} id
   * @param {EmailConfirmationBody} data
   * @param {ApiOption=} option
   * @return {Promise<void>}
   */
  async confirmEmail(id, data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(confirmEmailUrl(id, option), data);
  }

  /**
   * @name getUserDetailed
   * @param {number} userId
   * @param {ApiOption=} option
   * @return {Promise<UserDetailedResponse>}
   */
  async getUserDetailed(userId, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getUserDetailedUrl(userId, option));
  }

  /**
   * @name getcustomizationConfig
   * @param {ApiOption=} option
   * @return {Promise<CustomizationConfigResponse>}
   */
  async getCustomizationConfig(option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getCustomizationConfigUrl(option));
  }

  /**
   * @name editCustomizationConfig
   * @param {EditCustomizationConfigPayload} data
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async editCustomizationConfig(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editCustomizationConfigUrl(option), data);
  }
}

export const userService = new UserService();
