import React from 'react';
import styled from 'styled-components';
import { Paper, Row, Col } from '@geneui/components';

import { TranslationKeys as T } from '../../../../core/constants/translationKeys';
import { useMobileSize } from "../../../../hooks/useMobileSize";
import { LogoImg, RightSectionForm } from '../loginStyles';
import useTranslation from '../../../../hooks/useTranslation';
import Logo from '../../../../assets/images/logo.png';
import { Button } from '../../../components/shared';

const ResetLinkSentTitle = styled.span`
  font-size: 22px;
  font-weight: 600;
`;

const ResetLinkSentDesc = styled.p`
  font-size: 16px;
  line-height: 1.38;
`;

const LinkSentSuccessfully = ({ onBackToSignInClick }) => {

  const translate = useTranslation();
  const { isMobile } = useMobileSize();

  return (
    <Paper
      border
      justifyContent="center"
      cornerRadius="full-radius"
      padding={isMobile ? 20 : 76}
      className={`login-section right ${isMobile ? 'mobile' : ''}`}
    >
      <RightSectionForm isMobile={isMobile}>
        <Row span={1} padding={25}>
          <Col>
            <LogoImg src={Logo} isMobile={isMobile} />
          </Col>
          <Col>
            <ResetLinkSentTitle>
              { translate(T.RESET_LINK_SENT) }
            </ResetLinkSentTitle>
          </Col>
          <Col>
            <ResetLinkSentDesc>
            { translate(T.RESET_LINK_SENT_DESC) }
            </ResetLinkSentDesc>
          </Col>
          <Col>
            <Button
              size="big"
              type="button"
              color="confirm"
              flexibility="full-width"
              onClick={onBackToSignInClick}
            >
              { translate(T.BACK_TO_SIGN_IN) }
            </Button>
          </Col>
        </Row>
      </RightSectionForm>
    </Paper>
  );
};

export default LinkSentSuccessfully;
