import React, { useCallback, useMemo, useState } from 'react';
import { Paper, Row, Col, Alert } from '@geneui/components';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import { TranslationKeys } from '../../../../core/constants/translationKeys';
import { useMobileSize } from '../../../../hooks/useMobileSize';
import { LogoImg, RightSectionForm } from '../loginStyles';
import { userStore } from '../../../../module';
import PasswordChangedSuccessfully from '../enforceChangePasswordForm/passwordChangedSuccessfully';
import useTranslation from '../../../../hooks/useTranslation';
import Logo from '../../../../assets/images/logo.png';
import ResetLinkExpired from './resetLinkExpired';
import { Button, PasswordInput } from '../../../components/shared';

const ChangePassTitle = styled.span`
  font-size: 22px;
  font-weight: 600;
`;

const ChangePassDesc = styled.p`
  font-size: 16px;
  line-height: 1.38;
`;

const ResetPasswordForm = ({
  email,
  token,
  userId,
  setLinkSent,
  expirationDate,
}) => {

  const history = useHistory();
  const translate = useTranslation();
  const [inputValues, setInputValues] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const { isMobile } = useMobileSize();

  const handleChange = ({ target: { name, value } }) => {
    setInputValues(v => ({ ...v, [name]: value }));
  };

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    const { newPassword, confirmPassword } = inputValues;
    if (newPassword !== confirmPassword) {
      setErrorMessage(TranslationKeys.CONFIRM_PASSWORD_DOES_NOT_MATCH);
      setTimeout(() => {
        setErrorMessage('');
      }, 4000);
      return;
    }
    userStore.resetPassword({
      token,
      userId,
      newPassword
    })
    .then(() => {
      setIsPasswordChanged(true);
    })
    .catch(res => {
      const errorMessage = res?.data?.errorMessage ;
      if (errorMessage) {
        setErrorMessage(errorMessage);
        setTimeout(() => {
          setErrorMessage('');
        }, 4000);
      }
    });
  }, [inputValues, userId, token]);

  const handleBackToSignIn = useCallback(e => {
    history.push('/login');
  }, []);

  const changePassword = !inputValues.newPassword || !inputValues.confirmPassword;

  const isExpired = useMemo(() => {
    return moment.utc(expirationDate).valueOf() < Date.now();
  }, [expirationDate]);

  return (
    <Paper
      border
      justifyContent="center"
      padding={isMobile ? 20 : 76}
      cornerRadius="full-radius"
      className={`login-section right ${isMobile ? 'mobile' : ''}`}
    >
      {
        errorMessage ? (
          <Alert
            type="error"
            screenType="desktop"
            message={translate(errorMessage)}
            title={translate(TranslationKeys.ERROR)}
            className={`login-error-alert ${isMobile ? 'mobile' : ''}`}
          />
        ) : null
      }
      {
        isExpired ? (
          <ResetLinkExpired
            email={email}
            setLinkSent={setLinkSent}
            setErrorMessage={setErrorMessage}
          /> ) : (
          isPasswordChanged ?
          <PasswordChangedSuccessfully onBackToLogin={handleBackToSignIn} /> :
          <RightSectionForm onSubmit={handleSubmit} isMobile={isMobile}>
            <Row span={1} padding={25}>
              <Col>
                <LogoImg src={Logo} isMobile={isMobile} />
              </Col>
              <Col>
                <ChangePassTitle>
                  { translate(TranslationKeys.SET_NEW_PASSWORD) }
                </ChangePassTitle>
              </Col>
              <Col>
                <ChangePassDesc>
                { translate(TranslationKeys.YOUR_PASSWORD_HAS_EXPIRED_DESC) }
                </ChangePassDesc>
              </Col>
              <Col>
                <PasswordInput
                  inputSize="big"
                  name="newPassword"
                  value={inputValues.newPassword}
                  onChange={handleChange}
                  placeholder={translate(TranslationKeys.NEW_PASSWORD)}
                  cornerRadius={isMobile ? 'smooth-radius' : 'full-radius'}
                />
              </Col>
              <Col>
                <PasswordInput
                  inputSize="big"
                  name="confirmPassword"
                  onChange={handleChange}
                  value={inputValues.confirmPassword}
                  placeholder={translate(TranslationKeys.CONFIRM_PASSWORD)}
                  cornerRadius={isMobile ? 'smooth-radius' : 'full-radius'}
                />
              </Col>
              <Col>
                <Button
                  size="big"
                  type="submit"
                  flexibility="full-width"
                  disabled={changePassword}
                >
                  {translate(TranslationKeys.SET_NEW_PASSWORD)}
                </Button>
              </Col>
            </Row>
          </RightSectionForm>
        )
      }
    </Paper>
  );
};

export default ResetPasswordForm;
