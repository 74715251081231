import React, { useCallback, useState } from 'react';
import { Alert, Paper, Row, Col } from '@geneui/components';
import styled from 'styled-components';

import { TranslationKeys } from '../../../../core/constants/translationKeys';
import { useMobileSize } from '../../../../hooks/useMobileSize';
import { debounce } from '../../../../core/helpers/debounce';
import { LogoImg, RightSectionForm } from '../loginStyles';
import { authStore } from '../../../../module';
import useTranslation from '../../../../hooks/useTranslation';
import Logo from '../../../../assets/images/logo.png';
import { Button, PasswordInput, TextInput } from '../../../components/shared';

const SignInTitle = styled.span`
  font-size: 22px;
  font-weight: 600;
`;

const ForgotPass = styled.a`
  color: #1c82f4;
  margin-left: 1rem;
  cursor: pointer;
`;

const LoginForm = ({ onForgotPasswordClick }) => {

  const translate = useTranslation();
  const [inputValues, setInputValues] = useState({
    username: '',
    password: '',
  });
  const [error, setError] = useState(false);
  const { isMobile } = useMobileSize();

  const handleChange = ({ target: { name, value } }) => {
    setInputValues(v => ({ ...v, [name]: value }));
  }; 

  const handleLogin = debounce(() => {
    const { username, password } = inputValues;
    authStore.signIn({
      username: username.trim(),
      password: password.trim(),
    }).then(() => {
      authStore.setManualAuth(true);
      authStore.setAuth(true);
    }).catch(() => {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 4000);
    });
  }, 250, true);

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    handleLogin();
  }, [inputValues]);

  const signInDisabled = !inputValues.username || !inputValues.password;

  return (
    <Paper
      border
      justifyContent="center"
      cornerRadius="full-radius"
      padding={isMobile ? 20 : 76}
      className={`login-section right ${isMobile ? 'mobile' : ''}`}
    >
      {
        error ? (
          <Alert
            type="error"
            screenType="desktop"
            title={translate(TranslationKeys.ERROR)}
            className={`login-error-alert ${isMobile ? 'mobile' : ''}`}
            message={translate(TranslationKeys.WRONG_USERNAME_OR_PASSWORD)}
          />
        ) : null
      }
      <RightSectionForm onSubmit={handleSubmit} isMobile={isMobile}>
        <Row span={1} padding={25}>
          <Col>
            <LogoImg src={Logo} isMobile={isMobile} />
          </Col>
          <Col>
            <SignInTitle>
              { translate(TranslationKeys.SIGN_IN) }
            </SignInTitle>
          </Col>
          <Col>
            <TextInput
              inputSize="big"
              name="username"
              onChange={handleChange}
              placeholder={translate(TranslationKeys.USERNAME)}
              cornerRadius={isMobile ? 'smooth-radius' : 'full-radius'}
            />
          </Col>
          <Col>
            <PasswordInput
              inputSize="big"
              name="password"
              onChange={handleChange}
              value={inputValues.password}
              placeholder={translate(TranslationKeys.PASSWORD)}
              cornerRadius={isMobile ? 'smooth-radius' : 'full-radius'}
            />
          </Col>
          <Col>
            <ForgotPass onClick={onForgotPasswordClick}>
              {translate(TranslationKeys.FORGOT_PASSWORD)}?
            </ForgotPass>
          </Col>
          <Col>
            <Button
              size="big"
              type="submit"
              flexibility="full-width"
              disabled={signInDisabled}
            >
              {translate(TranslationKeys.SIGN_IN)}
            </Button>
          </Col>
        </Row>
      </RightSectionForm>
    </Paper>
  );
};

export default LoginForm;
