import React from 'react';
import styled from 'styled-components';

import { TranslationKeys } from '../../../../../core/constants/translationKeys';
import { useMobileSize } from "../../../../../hooks/useMobileSize";
import { RightSectionForm } from '../../loginStyles';
import useTranslation from '../../../../../hooks/useTranslation';
import { Button, Icon } from '../../../../components/shared';

const SuccessText = styled.p`
  font-size: 16px;
  line-height: 1.38;
  margin: 4rem 0 3rem;
`;

const SuccessIcon = styled.div`
  position: relative;
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 7.5rem;
  background-color: #2bc784;
  .success-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 100px;
  }
`;

const PasswordChangedSuccessfully = ({ onBackToLogin }) => {

  const translate = useTranslation();
  const { isMobile } = useMobileSize();

  return (
    <RightSectionForm isMobile={isMobile}>
      <SuccessIcon>
        <Icon className="success-icon" type="bc-icon-check" />
      </SuccessIcon>
      <SuccessText>
        { translate(TranslationKeys.PASSWORD_RESET_SUCCESS) }
      </SuccessText>
      <Button
        size="big"
        type="button"
        color="confirm"
        onClick={onBackToLogin}
        flexibility="full-width"
      >
        { translate(TranslationKeys.BACK_TO_SIGN_IN) }
      </Button>
    </RightSectionForm>
  );
};

export default PasswordChangedSuccessfully;
