import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Paper, Alert } from '@geneui/components';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import { TranslationKeys } from '../../../../core/constants/translationKeys';
import { useMobileSize } from '../../../../hooks/useMobileSize';
import { RightSectionForm } from '../loginStyles';
import { userStore } from '../../../../module';
import ConfirmationLinkExpired from './confirmationLinkExpired';
import useTranslation from '../../../../hooks/useTranslation';
import { Button, Icon, Loader } from '../../../components/shared';

const SuccessText = styled.p`
  font-size: 16px;
  line-height: 1.38;
  margin: 4rem 0 3rem;
`;

const SuccessIcon = styled.div`
  position: relative;
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 7.5rem;
  background-color: #2bc784;
  .success-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 100px;
  }
`;

const EmailConfirmation = ({
  token,
  userId,
  expireDate
}) => {

  const history = useHistory();
  const translate = useTranslation();
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ isLoading, setisLoading ] = useState(true);
  const { isMobile } = useMobileSize();
  
  const isExpired = useMemo(() => {
    return moment.utc(expireDate).valueOf() < Date.now();
  }, [expireDate]);

  useEffect(() => {
    if (!isExpired) {
      userStore
        .confirmEmail(userId, { token })
        .then(() => {
          setisLoading(false);
        })
        .catch((err) => {
          setErrorMessage(translate(err?.data?.errorMessage || TranslationKeys.SOMETHING_WENT_WRONG));
        });
    }
  }, []);

  const handleBackToSignIn = useCallback(e => {
    history.push('/login');
  }, []);

  return (
    <Paper
      border
      justifyContent="center"
      cornerRadius="full-radius"
      padding={isMobile ? 20 : 76}
      className={`login-section right ${isMobile ? 'mobile' : ''}`}
    >
      {
        errorMessage ? (
          <Alert
            type="error"
            screenType="desktop"
            message={translate(errorMessage)}
            title={translate(TranslationKeys.ERROR)}
            className={`login-error-alert ${isMobile ? 'mobile' : ''}`}
          />
        ) : null
      }
      {
        isExpired ? (
          <ConfirmationLinkExpired />
        ) : (
          isLoading ? (
            <Loader type="bubble" />
          ) : (
            <RightSectionForm isMobile={isMobile}>
              <SuccessIcon>
                <Icon className="success-icon" type="bc-icon-check" />
              </SuccessIcon>
              <SuccessText>
                { translate(TranslationKeys.EMAIL_CONFIRMED_SUCCESSFULLY) }
              </SuccessText>
              <Button
                size="big"
                type="button"
                color="confirm"
                onClick={handleBackToSignIn}
                flexibility="full-width"
              >
                { translate(TranslationKeys.BACK_TO_SIGN_IN) }
              </Button>
            </RightSectionForm>
          )
        )
      }
    </Paper>
  );
};

export default EmailConfirmation;
